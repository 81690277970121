import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import { ExternalHyperlink } from '@workday/canvas-kit-react/button';
import { InformationHighlight } from '@workday/canvas-kit-docs';
import { ShapeTokens } from '../../../src/components/content/tokens/TokenShapeGrid';
import { getInternalExternalURL } from '../../../src/utils/url';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PackageInfo = makeShortcode("PackageInfo");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const InternalContent = makeShortcode("InternalContent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <InformationHighlight mdxType="InformationHighlight">
  <InformationHighlight.Icon style={{
        width: '24px'
      }} />
  <InformationHighlight.Heading>Looking for our old shape tokens?</InformationHighlight.Heading>
  <InformationHighlight.Body>
    While we still support our old tokens, we’re migrating our documentation and usage guidance to
    reflect our new Tokens Web. It is <strong>not</strong> currently a requirement to update all
    your tokens to use v11, but we <strong>strongly</strong> encourage you to begin migrating as you
    can. If you're an engineer looking to migrate to our new tokens, we wrote a handy{' '}
    <ExternalHyperlink href={'https://github.com/Workday/canvas-tokens/discussions/77'} mdxType="ExternalHyperlink">
      migration guide
    </ExternalHyperlink>
    for you.
  </InformationHighlight.Body>
  <InformationHighlight.Link href={getInternalExternalURL('/v10/tokens/shape')}>
    View v10 Shape Token Docs.
  </InformationHighlight.Link>
    </InformationHighlight>
    <PackageInfo storybook="https://workday.github.io/canvas-tokens/?path=/docs/docs-system-tokens-shape--doc" figma="https://www.figma.com/design/GmIqhQ7KqX6WQZFimdn756/Canvas-Tokens-v2?node-id=21683-8979&t=zaRL8ALlvmcTsWQc-4" package="@workday/canvas-tokens-web" mdxType="PackageInfo" />
    <h2 {...{
      "id": "usage-guidance"
    }}>{`Usage Guidance`}</h2>
    <ul>
      <li parentName="ul">{`We can define the shape of an element by applying a border radius value to it. The border-radius
property defines the radius of the element's corners.`}</li>
      <li parentName="ul">{`Every Canvas elements presents a specific shape that responds to the use and/or characteristics of
this element.`}</li>
      <li parentName="ul">{`Our Canvas shape token includes five values ranging from a zero border radius for a rectangular
shape with sharp corners to a `}<inlineCode parentName="li">{`999px`}</inlineCode>{` border radius for a circular shape.`}</li>
    </ul>
    <h2 {...{
      "id": "canvas-shapes"
    }}>{`Canvas Shapes`}</h2>
    <p>{`Canvas Shape Tokens uses multipliers with a `}<inlineCode parentName="p">{`{base.unit}`}</inlineCode>{` of `}<inlineCode parentName="p">{`0.25rem`}</inlineCode>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Shape`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Size`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Recommended Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "200px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/0eca1287c896c99399866b002406fe9d/772e8/shape-0.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "50%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "shape 0",
                  "title": "shape 0",
                  "src": "/static/0eca1287c896c99399866b002406fe9d/772e8/shape-0.png",
                  "srcSet": ["/static/0eca1287c896c99399866b002406fe9d/772e8/shape-0.png 200w"],
                  "sizes": "(max-width: 200px) 100vw, 200px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`zero`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'0rem'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`This is the initial shape of every new element. Use this for `}<strong parentName="td">{`backgrounds screens`}</strong>{` and fixed navigation containers such as `}<strong parentName="td">{`headers`}</strong>{`, and `}<strong parentName="td">{`side-panels`}</strong>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "200px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/6c4fd1d59eca235273c4447ecd07900f/772e8/shape-xhalf.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "50%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "shape xhalf",
                  "title": "shape xhalf",
                  "src": "/static/6c4fd1d59eca235273c4447ecd07900f/772e8/shape-xhalf.png",
                  "srcSet": ["/static/6c4fd1d59eca235273c4447ecd07900f/772e8/shape-xhalf.png 200w"],
                  "sizes": "(max-width: 200px) 100vw, 200px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`xhalf`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'0.125rem'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use this for subtle and small components that don’t require to group complex information like `}<strong parentName="td">{`Status Indicators`}</strong>{`, `}<strong parentName="td">{`Checkboxes`}</strong>{` and `}<strong parentName="td">{`Color Swatches`}</strong>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "200px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/a872acdbefdecfc6b49789855445e7a7/772e8/shape-x1.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "50%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "shape x1",
                  "title": "shape x1",
                  "src": "/static/a872acdbefdecfc6b49789855445e7a7/772e8/shape-x1.png",
                  "srcSet": ["/static/a872acdbefdecfc6b49789855445e7a7/772e8/shape-x1.png 200w"],
                  "sizes": "(max-width: 200px) 100vw, 200px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'0.25rem'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use this for form components presenting text information or inputs: `}<strong parentName="td">{`Text Inputs`}</strong>{` and `}<strong parentName="td">{`Text Area`}</strong>{`, `}<strong parentName="td">{`Dropdowns`}</strong>{`, `}<strong parentName="td">{`Menus`}</strong>{`, and `}<strong parentName="td">{`Banners`}</strong>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "200px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/f8c008910990dd103403e44098232a82/772e8/shape-x2.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "50%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "shape x2",
                  "title": "shape x2",
                  "src": "/static/f8c008910990dd103403e44098232a82/772e8/shape-x2.png",
                  "srcSet": ["/static/f8c008910990dd103403e44098232a82/772e8/shape-x2.png 200w"],
                  "sizes": "(max-width: 200px) 100vw, 200px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'0.5'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use this for all the containers and popups: `}<strong parentName="td">{`Canvas Cards`}</strong>{`, `}<strong parentName="td">{`Modals`}</strong>{`, `}<strong parentName="td">{`Tooltips`}</strong>{`, and `}<strong parentName="td">{`Toasts`}</strong>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "200px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/de278e60e9c1de61a939a7e5bab9bc2f/772e8/shape-x4.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "50%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "shape x4",
                  "title": "shape x4",
                  "src": "/static/de278e60e9c1de61a939a7e5bab9bc2f/772e8/shape-x4.png",
                  "srcSet": ["/static/de278e60e9c1de61a939a7e5bab9bc2f/772e8/shape-x4.png 200w"],
                  "sizes": "(max-width: 200px) 100vw, 200px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x4`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'1rem'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile only: `}<strong parentName="td">{`Cards`}</strong>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "200px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/348fd7c272a44478c06b15fb67b403f0/772e8/shape-x6.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "50%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "shape x6",
                  "title": "shape x6",
                  "src": "/static/348fd7c272a44478c06b15fb67b403f0/772e8/shape-x6.png",
                  "srcSet": ["/static/348fd7c272a44478c06b15fb67b403f0/772e8/shape-x6.png 200w"],
                  "sizes": "(max-width: 200px) 100vw, 200px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`x6`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'1.5rem'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile only: `}<strong parentName="td">{`Sheets`}</strong>{`, `}<strong parentName="td">{`Modals`}</strong></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><span parentName="td" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "200px"
              }
            }}>{`
      `}<a parentName="span" {...{
                "className": "gatsby-resp-image-link",
                "href": "/static/0cb58802a82f6066fe4438ee89d570ff/772e8/shape-round.png",
                "style": {
                  "display": "block"
                },
                "target": "_blank",
                "rel": "noopener"
              }}>{`
    `}<span parentName="a" {...{
                  "className": "gatsby-resp-image-background-image",
                  "style": {
                    "paddingBottom": "50%",
                    "position": "relative",
                    "bottom": "0",
                    "left": "0",
                    "display": "block"
                  }
                }}></span>{`
  `}<img parentName="a" {...{
                  "className": "gatsby-resp-image-image",
                  "alt": "shape round",
                  "title": "shape round",
                  "src": "/static/0cb58802a82f6066fe4438ee89d570ff/772e8/shape-round.png",
                  "srcSet": ["/static/0cb58802a82f6066fe4438ee89d570ff/772e8/shape-round.png 200w"],
                  "sizes": "(max-width: 200px) 100vw, 200px",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  },
                  "loading": "lazy",
                  "decoding": "async"
                }}></img>{`
  `}</a>{`
    `}</span></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`round`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`'62.5rem'`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Commonly used for our standard buttons and profile avatars: All `}<strong parentName="td">{`Primary, Secondary Buttons`}</strong>{`, `}<strong parentName="td">{`Radio Buttons`}</strong>{` and `}<strong parentName="td">{`Notification Badges`}</strong>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "using-our-shapes"
    }}>{`Using our Shapes`}</h2>
    <p>{`When working with border-radius, there are certain things we need to consider:`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Be intentional when applying border-radius values following the usage guidelines above.`}</li>
        <li parentName="ul">{`All corners should always have the same value applied to ensure the shape doesn’t get distorted.`}</li>
      </ul>
    </Do>
    <Dont mdxType="Dont">
      <ul>
        <li parentName="ul">{`Avoid combining border-radius values.`}</li>
      </ul>
    </Dont>
    <h2 {...{
      "id": "web-examples"
    }}>{`Web Examples`}</h2>
    <h3 {...{
      "id": "javascript--typescript"
    }}>{`Javascript / Typescript`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`// styles.ts
import {system} from '@workday/canvas-tokens-web';

const styles = {
  borderRadius: \`var(\${system.shape.x2})\`,
};
`}</code></pre>
    <h3 {...{
      "id": "css"
    }}>{`CSS`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`// styles.css
.card {
  border-radius: var(--cnvs-sys-shape-x2);
}
`}</code></pre>
    <ShapeTokens mdxType="ShapeTokens" />
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "ios-examples"
      }}>{`iOS Examples`}</h2>
      <p>{`Coming soon!`}</p>
      <h2 {...{
        "id": "android-examples"
      }}>{`Android Examples`}</h2>
      <p>{`Coming soon!`}</p>
    </InternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      